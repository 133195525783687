.w-notif-wrap{
    top: 65px;
    right: 0px;
    width: 350px;
    font-family: var(--font);
    position: absolute;
    backdrop-filter: var(--backdropFilter);
    background: var(--primaryOpaque);
    color: var(--primaryFont);
    z-index: 9;
    border-radius: 10px;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    overflow: hidden;
}
.w-notif-wrap.show{
    visibility: visible;
    transform: translateY(0px);
    opacity: 1;
}
.w-header-notif-pos-wrap{
    position: relative;
}
.w-notif-header{
    font-family: var(--fontBold);
    text-align: left;
    padding: 10px 20px;
    background: var(--lightOpaque);
    border-bottom: 1px solid var(--secondaryActive);
}
.w-notif-main{
    padding: 10px;
    text-align: left;
    height: 250px;
    overflow: auto;
}
.w-notif-error{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: gray;
    i{
        font-size: 30px;
    }
    div{
        margin-top: 10px;
        font-size: 13px;
        width: 200px;
        text-align: center;
    }
}
.w-notif-item{
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none;
    color: grey;
    display: flex;
    transition: 0.2s;
    line-height: 20px;
    .w-notif-indicator{
        width: 17px;
        flex-shrink: 0;
        i{
            color: var(--accent);
            height: 100%;
            display: flex;
            justify-content: center;
            font-size: 8px;
            padding: 10px 5px;
        }
    }
    .w-notif-icon{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: var(--lightOpaque);
        width: 40px;
        height: 40px;
        font-size: 19px;
        border-radius: 15px;
        margin: 5px;
        flex-shrink: 0;
    }
    .w-notif-content{
        font-size: 14px;
        padding: 5px;
        .w-notification-time{
            color: gray;
            font-size: 12px;
        }
        .w-notif-link{
            color: var(--accent);
            text-decoration: none;
        }
    }
}
.w-notif-item.unread{
    color: var(--primaryFont);
}
.w-notif-item:hover{
    background: var(--lightOpaque);
}