.w-modal-wrap {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 20;
    backdrop-filter: var(--backdropFilter);
    background: var(--primaryOpaque);
    transition: 0.2s;
    visibility: hidden;
    opacity: 0;
    transform: scale(1.1);
}

.w-modal-wrap.show {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
}

.w-modal-container {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.w-modal-close {
    position: absolute;
    top: 30px;
    left: 30px;
    background: var(--lightOpaque);
    border-radius: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    font-size: 25px;
    transition: 0.2s;
    cursor: pointer;
    z-index: 110;
    color: var(--primaryFont);
    border: 0px;
    border: 1px solid transparent;
}

.w-modal-close:hover {
    border: 1px solid var(--accent);
}

.w-modal-panel-button {
    position: absolute;
    top: 30px;
    background: var(--lightOpaque);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;
    z-index: 110;
    color: var(--primaryFont);
    left: 20px;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    display: none;
}

.w-modal-main {
    position: relative;
    width: 50vw;
    height: 50vh;
    display: flex;
}

.w-modal-sidebar {
    width: 250px;
}

.w-modal-sidebar-button {
    transition: 0.2s;
    text-align: left;
    margin: 10px;
    border-radius: 10px;
    color: var(--primaryFont);
    padding: 10px;
    cursor: pointer;
    background: transparent;
    border: 0px;
    font-size: inherit;
    font-family: var(--font);
    width: calc(100% - 20px);
    display: block;
}

.w-modal-sidebar-button.select {
    color: var(--accent);
}

.w-modal-sidebar-button:hover {
    background: var(--lightOpaque);
}

.w-modal-sidebar-button i {
    width: 40px;
    text-align: center;
}

.w-modal-panel-wrap {
    width: 100%;
    overflow-x: auto;
    position: relative;
}

.w-modal-panel {
    padding: 10px;
    text-align: left;
    color: var(--primaryFont);
    position: absolute;
    right: 0px;
    left: 0px;
}

.w-modal-panel-title {
    font-family: var(--fontBold);
}

.w-modal-panel-title-max {
    font-family: var(--fontBold);
    font-size: 50px;
}

.w-modal-panel-select {
    padding: 10px 15px;
    color: var(--primaryFont);
    outline: 0px;
    border: 0px transparent;
    background: var(--lightOpaque);
    border-radius: 10px;
    cursor: pointer;
    font-family: var(--font);
}

.w-modal-panel-select option {
    background: var(--secondary);
}

.w-modal-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.w-modal-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.w-modal-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--secondaryActive);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 24px;
}

.w-modal-switch-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.w-modal-switch-slider {
    background-color: var(--accent);
}

input:focus+.w-modal-switch-slider {
    box-shadow: 0 0 1px var(--accent);
}

input:checked+.w-modal-switch-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.w-modal-panel-slider-wrap {
    width: 100%;
}

.w-modal-panel-slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 10px;
    /* Specified height */
    background: var(--secondaryActive);
    /* Grey background */
    outline: none;
    /* Remove outline */
    opacity: 0.7;
    /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 15px;
}

.w-modal-panel-slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
}

.w-modal-panel-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: var(--accent);
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 50%;
}

.w-modal-panel-slider::-moz-range-thumb {
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: var(--accent);
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 50%;
}

.w-modal-panel-tile {
    padding: 10px;
    margin: 5px 0px;
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 10px;
    color: grey;
    text-decoration: none;
}

.w-modal-panel-tile:hover {
    background: var(--lightOpaque);
}

.w-modal-panel-tile-img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: inline-block;
    flex-basis: 40px;
    flex-grow: 0;
    flex-shrink: 0;
}

.w-modal-panel-tile-info {
    display: inline-block;
    padding-left: 10px;
    width: 100%;
}

.w-modal-panel-tile-date {
    color: grey;
}

.w-spm-lbar {
    width: 200px;
    text-align: left;
}

.w-spm-cover {
    border-radius: 10px;
    width: 200px;
    height: 300px;
    background: var(--secondary);
    display: inline-block;
}

.w-spm-wrap {
    display: flex;
    justify-content: center;
    height: calc(100% - 200px);
}

.w-spm-rbar {
    width: 40vw;
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
    overflow: auto;
}

.w-spm-title {
    font-size: 50px;
    line-height: 60px;
    font-family: var(--fontBold);
}

.w-spm-user {
    margin-left: 10px;
}

.w-spm-user-wrap {
    display: inline-flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
}

.w-spm-user-img {
    background: var(--secondary);
    height: 35px;
    width: 35px;
    border-radius: 10px;
}

.w-spm-button-wrap {
    text-align: right;
}

.w-spm-read-button {
    background: var(--accent);
    color: white;
    border-radius: 8px;
    padding: 10px 30px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.2s;
    margin: 5px;
}

.w-spm-read-button:hover {
    background: var(--accentActive);
}

.w-spm-secondary-button {
    background: var(--secondary);
    color: var(--primaryFont);
    border-radius: 8px;
    padding: 10px 30px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.2s;
    margin: 5px;
}

.w-spm-secondary-button:hover {
    background: var(--secondaryActive);
}

.w-spm-read-button i,
.w-spm-secondary-button i {
    margin-right: 10px;
}

.w-modal-inline-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 70px;
    height: 70px;
    border-radius: 10px;
    font-size: 25px;
    background: var(--secondary);
    cursor: pointer;
    transition: 0.2s;
    margin: 10px;
}

.w-modal-inline-box:hover {
    filter: brightness(120%);
}

.w-modal-input {
    padding: 10px 15px;
    border-radius: 8px;
    background: var(--lightOpaque);
    font-family: var(--font);
    font-size: 16px;
    color: var(--primaryFont);
    border: 1px solid transparent;
    outline: 0px;
    transition: 0.2s;
    width: 200px;

    &.input-max {
        font-size: 24px;
        width: 500px;
    }
}

.w-modal-textarea {
    padding: 10px 15px;
    border-radius: 8px;
    background: var(--lightOpaque);
    font-family: var(--font);
    color: var(--primaryFont);
    border: 1px solid transparent;
    outline: 0px;
    transition: 0.2s;
    width: calc(100% - 30px);
    font-size: 16px;
    height: 200px;
    resize: none;
}

.w-modal-textarea:focus,
.w-modal-input:focus {
    border: 1px solid var(--accent);
}

.w-modal-cropper-wrap {
    width: 800px;
    height: 450px;
}

.lib-cropper-edit-block {
    max-width: 100%;
    display: none;
}

.w-modal-panel-solo-button {
    background: var(--lightOpaque);
    display: inline-block;
    padding: 15px 50px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    margin: 5px;
}

.w-modal-panel-solo-button:hover {
    background: var(--opaque);
}

.w-modal-panel-solo-button i {
    margin-right: 10px;
}

.w-modal-panel-icon-option {
    width: 55px;
    height: 55px;
    background: var(--secondary);
    font-size: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;
    text-decoration: none;
    color: inherit;
}

.w-modal-panel-icon-option:hover {
    background: var(--secondaryActive);
}

.w-mi-singular {
    width: 50%;
}

.w-modal-a-button {
    padding: 10px 30px;
    background: var(--accent);
    border: 1px solid var(--accent);
    color: white;
    border-radius: 8px;
    cursor: pointer;
    font-family: var(--font);
    transition: 0.2s;
}

.w-modal-a-button:hover {
    background: var(--accentActive);
}

.w-modal-s-button {
    padding: 10px 30px;
    background: var(--lightOpaque);
    border: 0px;
    color: inherit;
    border-radius: 8px;
    cursor: pointer;
    font-family: var(--font);
    transition: 0.2s;
}

.w-mpt-f {
    display: flex;
    justify-content: space-between;
}

.w-modal-panel-title-icon {
    font-size: 70px;
    color: var(--warning);
}

.w-modal-panel-w-title {
    font-size: 25px;
    font-family: var(--fontBold);
    width: 60%;
    display: inline-block;
    margin-bottom: 20px;
    border: 0px;
    text-align: center;
}

.w-modal-panel-w-content {
    width: 400px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-mpw-cancel {
    background: var(--secondary);
    font-family: var(--fontBold);
    color: var(--primaryFont);
    padding: 10px 30px;
    border-radius: 8px;
    cursor: pointer;
    border: 0px;
    margin: 10px;
    transition: 0.2s;

    &:hover {
        background: var(--secondaryActive);
    }
}

.w-mpw-proceed {
    background: var(--warning);
    font-family: var(--fontBold);
    color: black;
    padding: 10px 30px;
    border-radius: 8px;
    cursor: pointer;
    border: 0px;
    margin: 10px;
    transition: 0.2s;

    &:hover {
        background: var(--warningActive);
    }
}

.w-modal-panel-indi {
    background: transparent;
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

.w-modal-panel-indi.show {
    background: var(--accent);
}

.w-mup-up {
    height: 2px;
    width: 300px;
}

.w-mup-p {
    width: 0%;
    background: var(--accent);
    height: 100%;
    transition: 0.1s;
}

.w-modal-upload-progress {
    display: none;
}

.w-modal-input-flex {
    display: flex;
    align-items: center;
    justify-items: stretch;
    width: 100%;
    gap: 10px;

    input {
        flex: 1;
    }
}

@media screen and (max-width:1600px) {
    .w-modal-main {
        width: 70vw;
        height: 70vh;
    }
}

@media screen and (max-width:800px) {
    .w-modal-main {
        width: 100vw;
        height: 100vh;
    }

    .w-modal-sidebar {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: -100vw;
        background: var(--primary);
        z-index: 10;
        padding-top: 80px;
        transition: 0.2s;
    }

    .w-modal-close {
        left: 80px;
        border-radius: 10px;
        width: 50px;
        height: 50px;
        font-size: 20px;
    }

    .w-modal-panel-button {
        display: flex;
    }

    .w-modal-panel {
        padding: 20px;
        margin-top: 80px;
    }

    .w-modal-sidebar.show {
        left: 0px;
    }
}

.w-modal-upload-cropbox-wrap {
    text-align: center;
    display: inline-block;
}

.final-crop-show {
    width: 300px;
}

.w-muc-prev-wrap {
    width: 300px;
    height: 300px;
    background: var(--secondary);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    display: inline-block;
}

.w-modal-panel-report-option {
    background: var(--lightOpaque);
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    text-align: left;
    transition: 0.2s;
    outline: 1px solid transparent;
}

.w-modal-panel-report-option:hover {
    background: var(--opaque);
}

.w-modal-panel-report-option.selected {
    color: var(--accent);
    outline: 1px solid var(--accent);
}

.w-modal-panel-report-o-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.w-modal-panel-screen {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
}

.w-modal-panel-screen.hidden {
    display: none;
}

.w-modal-theme-wrap {
    display: flex;
    flex-wrap: wrap;
}

.w-modal-theme-box {
    display: inline-flex;
    flex-direction: column;
    width: 150px;
    margin: 10px;
    cursor: pointer;
    border-radius: 10px;
    background: var(--primary);
    outline: 0px solid var(--accent);
    overflow: hidden;
    position: relative;

    input {
        display: none;
    }

    .w-modal-theme-box-canvas {
        height: 100px;
        position: relative;

        overflow: hidden;

        .w-tbc-page {
            position: absolute;
            bottom: 0px;
            left: 0px;
            top: 0px;
            right: 0px;
            background: white;
            border-radius: 0px 10px 0px 0px;
            padding: 20px;

            .w-tbc-text {
                height: 5px;
                border-radius: 3px;
                width: 50%;
                margin-bottom: 3px;
            }
        }
    }

    .w-modal-theme-box-span {
        font-family: var(--fontBold);
        padding: 5px;
        text-align: center;
    }
}

.w-modal-theme-box:hover {
    background: var(--secondary);
}

.w-modal-theme-box.selected {
    outline: 3px solid var(--accent);
}

.w-modal-tags {
    background: var(--secondary);
    border: 1px solid var(--secondaryActive);
    margin-right: 10px;
    border-radius: 10px;
    padding: 5px 15px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    button {
        background: transparent;
        border: none;
        cursor: pointer;
        color: inherit;
        margin-left: 10px;
        padding: 0px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}

.w-modal-share-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .w-modal-share-button {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        border: 0px;
        display: inline-block;
        font-size: 25px;
        margin-bottom: 10px;
        cursor: pointer;
        background: var(--secondaryActive);
        color: var(--primaryFont);
    }
}