.w-error-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - var(--headerHeight));
}
.w-error-container{
    text-align: center;
    width: 400px;
    font-size: 13px;
    h1{
        font-family: var(--fontBold);
        margin: 0px;
        margin-bottom: 0px;
    }
    .w-error-image{
        width: 150px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width:500px){
    .w-error-container{
        width: 100%;
        margin: 20px;
    }
}