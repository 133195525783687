@font-face {
    font-family: 'Dancing Script';
    src: url('../media/fonts/feed/DancingScript-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'Grenze Gotisch';
    src: url('../media/fonts/feed/GrenzeGotisch-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'Indie Flower';
    src: url('../media/fonts/feed/IndieFlower-Regular.ttf');
}
@font-face {
    font-family: 'Lobster';
    src: url('../media/fonts/feed/Lobster-Regular.ttf');
}
@font-face {
    font-family: 'Playfair';
    src: url('../media/fonts/feed/PlayfairDisplay-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'Raleway';
    src: url('../media/fonts/feed/Raleway-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'Roboto';
    src: url('../media/fonts/feed/RobotoMono-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'Rowdies';
    src: url('../media/fonts/feed/Rowdies-Regular.ttf');
}
.w-font-inconsolata{
    font-family: 'Inconsolata', monospace;
}
.w-font-indie-flower{
    font-family: 'Indie Flower', cursive;
}
.w-font-merriweather{
    font-family: 'Merriweather', serif;
}
.w-font-open-sans{
    font-family: 'Open Sans', sans-serif;
}
.w-font-dynamic{
    font-family: var(--font_family, 'Merriweather', serif);
    font-size: var(--font_size, 15px);
    line-height: var(--font_lineHeight, 17px);
}