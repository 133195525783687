.w-fallback-block{
    background: var(--secondary);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
.w-fallback-block::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -100px;
    top: 0;
    height: 100%;
    width: 100px;
    background: linear-gradient(to right, transparent 0%, var(--primaryOpaque) 50%, transparent 100%);
    transition: 0.4s;
    animation: lazyload 1s infinite cubic-bezier(0.4, 0.0, 0.2, 1);
}
@keyframes lazyload {
    from {
        left: -100px;
    }
    to   {
        left: 100%;
    }
}
.w-loading-icon{
    background: url('../media/icon_home.png');
    background-position: center;
    background-size: cover;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
}
.w-loading-icon::after{
    z-index: -1;
    content: '';
    position: absolute;
    top:0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(68, 197, 77, 0.475);
    animation: feed-blink 1s infinite;
    border-radius: 50%;
}
.w-loading-progress-wrap{
    height: 5px;
    border-radius: 10px;
    background: var(--secondary);
    width: 300px;
    display: inline-block;
    position: relative;
    overflow: hidden;
}
.w-loading-progress{
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    border-radius: 10px;
    background: var(--accent);
    width: 0%;
    transition: 0.2s;
}
@keyframes feed-blink{
    0%{
        top:0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        opacity: 1;
    }
    50%{
        top:-50px;
        left: -50px;
        right: -50px;
        bottom: -50px;
        opacity: 0;
    }
    99%{
        opacity: 0;
    }
    100%{
        top:0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        opacity: 1;
    }
}
.w-loading-screen{
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background: var(--primary);
    z-index: 100;
}
.w-loading-main{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100%;
}
.w-loading-main-wrap{
    text-align: center;
}
.w-loading-tip{
    width: 400px;

}
.w-placeholder-block{
    border-radius: 10px;
    background: var(--secondary);
    position: relative;
    overflow: hidden;
}
@media screen and (max-width:850px){
    .w-loading-main-wrap{
        width: calc(100vw - 60px);
    }
    .w-loading-tip{
        width: 100%;
    }
    .w-loading-progress-wrap{
        width: 100%;
    }
}