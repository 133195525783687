.w-alert{
    background: var(--secondary);
    border-radius: 10px;
    display: flex;
    gap: 20px;
    padding: 20px;
    max-width: 300px;
    align-items: center;
    .w-alert-icon{
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    .w-alert-close{
        background: transparent;
        border: 0px;
        cursor: pointer;
        color: inherit;
    }
}