.w-sidebar {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: var(--sidebarWidth);
    border-right: 1px solid var(--secondary);
    z-index: 9;
    background: var(--primary);
    display: flex;
    flex-direction: column;
}

.w-sidebar-logo-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    height: var(--headerHeight);
    margin-left: 30px;
    flex-shrink: 0;

    .w-sidebar-logo {
        height: 35px;
    }

    .w-sidebar-logo-title {
        font-size: 20px;
    }

    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.w-sidebar-nav-wrap {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 3px;
        display: none;
    }

    &:hover::-webkit-scrollbar {
        display: block;
    }
}

.w-sidebar-nav-container {
    border-bottom: 1px solid var(--secondary);
}

.w-sidebar-nav-heading {
    font-size: 10px;
    color: gray;
    padding: 10px 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: var(--fontBold);

    i {
        width: 24px;
    }
}

.w-sidebar-nav-button {
    display: flex;
    align-items: center;
    position: relative;
    gap: 10px;
    padding: 10px 22px;
    font-size: 14px;

    transition: all 0.2s ease;
    cursor: pointer;
    color: inherit;
    text-decoration: none;

    &:hover {
        background-color: var(--secondary);
        opacity: 1;

        .w-snb-cog {
            display: block;
        }
    }

    i {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

    }

    &.active {
        color: var(--accent);
        opacity: 1;
    }

    .w-snb-cog {
        position: absolute;
        right: 15px;
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        display: none;
    }

    opacity: 0.8;
}

.w-sidebar-parts-list {
    margin-left: 35px;
    border-left: 2px solid var(--secondary);
    max-height: 0px;
    transition: 0.2s;
    overflow: hidden;

    .w-sidebar-nav-button-parts {
        display: block;
        background: transparent;
        font-size: inherit;
        font-family: inherit;
        border: 0px;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        padding: 5px 20px;
        font-size: 14px;
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 10px;

        i {
            font-size: 10px;
        }

        &:hover {
            background-color: var(--secondary);
            opacity: 1;
        }

        &.active {
            color: var(--accent);
            opacity: 1;
        }

        opacity: 0.8;
    }
}

.w-sidebar-open {
    .w-nav-collapsible {
        .w-open-icon {
            transform: rotate(90deg);
        }
    }

    .w-sidebar-parts-list {
        max-height: 1000px;
        overflow: auto;
    }
}

@media screen and (max-width: 768px) {
    .w-sidebar {
        left: -100%;
        width: 100%;
        border-right: none;
        z-index: 11;
    }
}