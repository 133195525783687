@import "./fonts.scss";
@import "./fallback.scss";

@font-face {
    font-family: manrope;
    src: url('/media/fonts/medium.otf');
}

@font-face {
    font-family: manrope-bold;
    src: url('/media/fonts/bold.otf');
}

:root {
    --headerHeight: 62px;
    --sidebarWidth: 300px;
}

body {
    font-family: var(--font);
    color: var(--primaryFont);
    margin: 0px;
    background: var(--primary);
}

.w-page {
    margin-top: var(--headerHeight);
    margin-left: var(--sidebarWidth);
    min-height: calc(100vh - var(--headerHeight));
}

.w-page-padder {
    padding: 20px 50px;
}

.w-button {
    --v-color: var(--accent);
    --v-bg-hover: var(--accentActive);
    color: inherit;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 10px;
    color: var(--primary);
    display: inline-block;
    border: 0px;
    transition: 0.2s;
    font-size: inherit;
    font-family: var(--fontBold);
    line-height: 18px;
    cursor: pointer;
    background: var(--v-color);
    border: 2px solid var(--v-color);
    position: relative;
    overflow: hidden;

    &:hover {
        background: var(--v-bg-hover);
        border: 2px solid var(--v-bg-hover);
    }

    &.w-button-primary {
        --v-color: var(--accent);
        --v-bg-hover: var(--accentActive);
    }

    &.w-button-secondary {
        --v-color: var(--secondary);
        --v-bg-hover: var(--secondaryActive);
    }

    &.w-button-danger {
        --v-color: var(--danger);
        --v-bg-hover: var(--dangerActive);
    }

    &.w-button-warning {
        --v-color: var(--warning);
        --v-bg-hover: var(--warningActive);
    }

    &.w-button-ghost {
        background: transparent;
        border: 2px solid var(--v-color);
        color: var(--v-color);

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: var(--v-color);
            opacity: 0;
            transition: 0.2s;
        }

        &:hover {
            &::before {
                opacity: 0.1;
            }
        }
    }
}

.w-full {
    width: 100%;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: var(--primary);
}

::-webkit-scrollbar-thumb {
    background: var(--opaque);
    transition: 0.2s;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--opaqueActive);
}

.w-multi-dropdown {
    max-height: 45px;
    display: inline-block;
    border: 1px solid var(--secondary);
    border-radius: 10px;
    position: absolute;
    transition: 0.2s;
    overflow: hidden;
    cursor: pointer;
    z-index: 10;
    background: var(--primary);

    .w-md-text {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            margin-left: 20px;
            transition: 0.2s;
        }

        i.rotate {
            transform: rotate(180deg);
        }
    }

    .w-md-dropdown-list {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-direction: column;
        border: 1px solid var(--secondary);
        min-height: 50px;
        max-height: 200px;
        border: 10px;
        overflow: auto;

        label {
            display: block;
            transition: 0.2s;

            input {
                display: none;
            }

            cursor: pointer;

            padding: 10px 20px;

            i {
                color: var(--secondary);
                margin-right: 10px;
                transition: 0.2s;
            }

            i.selected {
                color: var(--accent);
            }
        }

        label:hover {
            background: var(--lightOpaque);
        }
    }
}

.w-multi-dropdown:hover {
    border: 1px solid var(--secondaryActive);
}

.w-multi-dropdown.extended {
    max-height: 250px;
}

.w-green-link {
    color: var(--accent);
    text-decoration: none;
    transition: 0.2s;
    background: transparent;
    border: 0px;
    font-size: inherit;
    padding: 0px;
    cursor: pointer;
}

.w-green-link:hover {
    color: var(--accentActive);
}

.w-page-title {
    font-size: 40px;
    font-family: var(--fontBold);
}

.w-flex-full {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-card {
    margin: 30px;
}

.w-heading {
    font-family: var(--fontBold);
    font-size: 30px;
}

.w-input {
    box-sizing: border-box;
    background: var(--secondary);
    border: 0px;
    padding: 10px 20px;
    width: 100%;
    border-radius: 10px;
    color: var(--primaryFont);
    font-family: var(--font);
    outline: 0px;
    border: 1px solid transparent;
    transition: 0.2s;
    font-size: 16px;
    margin-top: 5px;

    &:focus {
        border: 1px solid var(--accent);
    }

    &.w-textarea {
        resize: none;
        height: 200px;
    }

    &:disabled {
        color: gray;
    }
}

.w-input-label {
    color: gray;
}

.w-input-error {
    color: red;
    font-size: small;
}

.w-input-button {
    box-sizing: border-box;
    background: var(--accent);
    border: 0px;
    padding: 10px 20px;
    width: 100%;
    border-radius: 10px;
    color: var(--primary);
    font-family: var(--fontBold);
    outline: 0px;
    border: 1px solid transparent;
    transition: 0.2s;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        background: var(--accentActive);
    }
}

.w-premium-indicator {
    color: gold;
    font-size: smaller;
}

.w-flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    :root {
        --sidebarWidth: auto;
    }
}