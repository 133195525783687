header {
    font-family: var(--font);
    position: fixed;
    top: 0px;
    left: var(--sidebarWidth);
    right: 0px;
    display: flex;
    align-items: center;
    z-index: 10;
    height: var(--headerHeight);
    padding: 0px 30px;
    padding-left: 0px;
    transition: 0.2s;
    background: var(--primaryOpaque);
    backdrop-filter: var(--backdropFilter);
    /*box-shadow: 0 5px 5px -5px #999;*/
    border-bottom: 1px solid var(--secondary);

    @media screen and (max-width: 800px) {
        left: 0px;
    }

    &.w-header-collapsible.hidden {
        top: -var(--headerHeight);
    }

}

.w-header-title {
    font-size: 20px;
    padding: 10px 20px;
    width: 100%;
    font-family: var(--fontBold);
}

.w-header-search-wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.w-header-search-bar {
    font-family: var(--font);
    background: var(--secondary);
    color: var(--primaryFont);
    border: 1px solid transparent;
    padding: 10px;
    width: 500px;
    border-radius: 10px;
    outline: 0px;
    margin-left: 20px;
    transition: 0.2s;
}

.w-header-search-bar:focus {
    background: var(--secondaryActive);
}

.w-header-nav-wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 40px;
}

.w-header-nav-button {
    font-size: 22px;
    color: var(--primaryFont);
    width: 44.4px;
    height: 44.4px;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    transition: 0.2s;
    margin: 10px;
    cursor: pointer;
    border: 0px;
    background: transparent;
}

.w-header-nav-button:hover {
    background: var(--secondary);
}

.w-header-user-img {
    width: 30px;
    height: 30px;
    border-radius: 10px;
    background: var(--secondaryActive);
}

.w-header-user-wrap {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    width: 100%;
}

.w-header-user-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 10px;
    height: 44.4px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 10px;
    color: var(--primaryFont);
    border: 0px;
    font-family: var(--font);
    font-size: inherit;
    background: transparent;
}

.w-header-username {
    margin: 0px 10px;

}

.w-header-user-button:hover {
    background: var(--secondary);
}

.w-header-submenu {
    font-family: var(--font);
    position: fixed;
    top: 65px;
    right: 30px;
    width: 200px;
    backdrop-filter: var(--backdropFilter);
    background: var(--primaryOpaque);
    color: var(--primaryFont);
    z-index: 10;
    border-radius: 10px;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
}

.w-header-submenu.show {
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;
}

.w-header-submenu-button {
    margin: 5px;
    margin-bottom: 0px;
    padding: 10px;
    flex: 1;
    transition: 0.2s;
    border-radius: 10px;
    cursor: pointer;
    color: var(--primaryFont);
    font-family: var(--font);
    text-decoration: none;
    border: 0px;
    font-size: 15px;
    background: transparent;
    text-align: left;
}

.w-header-submenu-button:hover {
    background: var(--lightOpaque);

}

.w-header-submenu-button i {
    width: 35px;
    text-align: center;
}

.w-header-search-result-wrap {
    position: absolute;
    top: 70px;
    left: 20px;
    right: 0px;
    height: 0px;
    transition: 0.2s;
    backdrop-filter: blur(20px);
    background: var(--primaryOpaque);
    border-radius: 10px;
    overflow: hidden;
}

.w-header-search-result-wrap.show {
    height: 400px;
    overflow: auto;
}

.w-header-search-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    margin: 10px;
    color: inherit;
    text-decoration: none;
}

.api-dummy.w-header-search-item {
    display: none;
}

.w-search-item-qmark {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primaryFont);
    background: var(--lightOpaque);
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.w-search-item-info {
    padding-left: 10px;
}

.w-header-search-item:hover {
    background: var(--lightOpaque);
}

header.nav-up {
    top: -70px;
}

.w-header-search-button {
    display: none;
}

.w-header-load-strip {
    height: 2px;
    background: var(--accent);
    width: 0%;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: 0.2s;
}

.w-header-notif-button {
    position: relative;
}

.w-notif-num-wrap {
    --this-w: 20px;
    position: absolute;
    top: 0px;
    left: calc(100% - 20px);
    font-size: 11px;
    color: var(--primary);
    background: var(--accent);
    border-radius: 50%;
    min-width: var(--this-w);
    padding: 2px;
    max-width: 30px;
    height: var(--this-w);
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-header-login {
    background: var(--accent);
    border-radius: 10px;
    color: var(--primary);
    padding: 5px 20px;
    text-decoration: none;
    display: inline-block;
    transition: 0.2s;
}

.w-header-login:hover {
    background: var(--accentActive);
}

.w-h-settings {
    background: var(--secondary);
    border-radius: 10px;
    color: inherit;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    border: 0px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.2s;
    margin-left: 10px;
}

.w-h-settings:hover {
    background: var(--secondaryActive);
}

@media screen and (max-width:1190px) {
    .w-header-username {
        display: none;
    }

    .w-header-drop {
        margin-left: 10px;
    }

    .w-header-search-bar {
        width: 300px;
    }
}

@media screen and (max-width:850px) {
    .w-header-search-wrap.show {
        top: 70px;
    }

    .w-header-search-bar {
        width: 100%;
        margin: 0px;
        border-radius: 0px;
    }

    .w-header-logo-wrap {
        display: none;
    }

    .w-header-search-button {
        display: inline-flex;
    }

    .w-header-nav-wrap {
        padding: 0px;
        display: flex;
        width: 100%;
    }

    .w-header-user-wrap {
        margin: 0px;
    }

    header {
        padding: 0px;
        justify-content: center;
    }

    .w-h-hide {
        display: none;
    }

    .w-header-login {
        padding: 15px !important;
    }

    .w-header-search-result-wrap {
        top: 44px;
        left: 0px;
    }

    .w-header-search-result-wrap.show {
        height: calc(100vh - 44px - 90px);
    }

    .w-header-submenu {
        right: 10px;
    }
}